import React from "react";
import BioSxanLogoSVG from "../../svgs/biosxanlogowhite.svg";

/**
 * Generates a component that renders the 404 customer content.
 */
export default ({ children }) => {
  return (
    <div className="flex min-h-screen font-body">
      <div className="flex flex-wrap justify-center w-full overflow-hidden">
        <div className="flex justify-center w-full overflow-hidden">
          <BioSxanLogoSVG className="block w-full h-16 p-2 my-4 text-center" />
        </div>
        <div className="flex justify-center w-full overflow-hidden">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};
