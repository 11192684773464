import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import NotFoundSVG from "../svgs/biosxan404.svg";
import View from "../components/404View";

/**
 * Generates a custom 404 page for the application.
 */
const NotFound = () => (
  <Layout>
    <View title="Not Found">
      <div className="w-full">
        <NotFoundSVG className="block mx-auto text-center border border-transparent" />
      </div>
      <div className="flex justify-center w-full py-8">
        <Link to="/app/profile" className="w-full p-2 mx-4 text-sm font-bold text-center text-white bg-gray-600 border border-gray-600 rounded-lg shadow-md hover:bg-gray-800">
          Home
        </Link>
      </div>
    </View>
  </Layout>
);

export default NotFound;
